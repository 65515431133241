import React from 'react';
import { graphql } from 'gatsby';
import tw, { css } from 'twin.macro';
import Action from '../atoms/action';
import Sandbox from '../atoms/sandbox';
import Breadcrumb from '../atoms/breadcrumb';

const Text = ({ data, theme, nextContainerType }) => (
  <div
    css={[
      tw`relative pt-6 px-6 pb-12`,
      tw`sm:(px-20 pt-12 pb-20)`,
      tw`lg:(px-28 pt-20 pb-28)`,
      tw`xl:(px-48 pt-24)`,
      css`
        & + .app-text-container {
          ${tw`pt-0`}
        }
      `,
      css`
        ol {
          list-style-type: none;
          counter-reset: app-sandbox-counter;
          ${tw`mt-4 2xl:mt-8`}
          li {
            counter-increment: app-sandbox-counter;
            ${tw`mb-4 relative xl:mb-6`}
            &:before {
              content: counter(app-sandbox-counter) '. ';
              ${tw`text-brand-purple-800 font-bold md:(absolute top-0 right-full mr-2)`}
            }
          }
        }
      `,
      nextContainerType === 'STRAPI_ComponentContainersText' &&
        tw`(pb-6 2xl:pb-8)!`,
    ]}
    className="app-text-container"
  >
    <div tw="max-w-screen-lg mx-auto text-center md:text-left">
      {data.breadcrumb && <Breadcrumb text={data.breadcrumb} theme={theme} />}
      <h3 tw="text-3xl leading-none font-black text-brand-green mb-6 lg:text-4xl">
        {data.title}
      </h3>
      <Sandbox
        html={data.intro}
        tw="xl:(text-xl leading-relaxed) 2xl:(text-2xl leading-relaxed)"
      />
      <Sandbox
        html={data.body}
        tw="mt-6 xl:(text-lg leading-relaxed) 2xl:(text-xl leading-relaxed)"
      />
      <div tw="flex flex-wrap items-center justify-center mt-8 2xl:(mt-16 text-xl)">
        {data.actions.map((action) => (
          <Action tw="mb-4" data={action} key={action.id} />
        ))}
      </div>
    </div>
  </div>
);

export const query = graphql`
  fragment Text on STRAPI_ComponentContainersText {
    id
    title
    intro
    body
    breadcrumb
    actions {
      ...Action
    }
  }
`;

export default Text;
